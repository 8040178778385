import React from "react";
import Helmet from "react-helmet";
import Header from "../components/layouts/header";
import css from "@emotion/css";
import { graphql } from "gatsby";
import PrimaryButton from "../components/PrimaryButton";

import { HeroBanner } from "../components/HeroBanner";
import Recapture from "react-google-recaptcha";
const formCSS = css`
  border: none;
  border-radius: 0;
  margin-bottom: 1rem;
  width: 100%;
  color: #1d1d1d;
  font-size: 12px;
  line-height: 15px;
  padding: 20px;
`;
function Index({ data }) {
  const {
    contentfulContactPage: {
      pageTitle,
      metaDescription,
      heroBanner: { title, subtitle, buttonText, buttonLink, heroImage },
      contactContent
    }
  } = data;
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="Ratio, fintech, finance, software, contact Ratio"
        />
      </Helmet>
      <div>
        <div className="hero bg-gradient">
          {" "}
          <Header />
          <div id="heroBanner" className="container section-py">
            <HeroBanner
              version="top"
              heroImage={heroImage}
              subtitle={subtitle}
              buttonLink={buttonLink}
              buttonText={buttonText}
              title={title}
            />
          </div>
        </div>
      </div>
      <section
        css={css`
          background-color: #e7eaea;
        `}
      >
        <div className="container">
          <div className="row section-py">
            <div
              className="col-md-5 offset-md-1"
              css={css`
                p {
                  margin-bottom: 0;
                }
                h3 {
                  font-family: "futura-pt", sans-serif;
                  font-weight: 600;
                  font-size: 2.5em;
                  margin-bottom: 1rem;
                  color: var(--primary);
                }
              `}
              dangerouslySetInnerHTML={{
                __html: contactContent.md.html.replace(/\n/g, "<br />")
              }}
            />
            <div className="col-md-4 offset-md-1">
              <form
                name="contact ratio"
                method="POST"
                action="/thank-you"
                data-netlify="true"
                data-netlify-recaptcha="true"
                className="form-group"
                css={css`
                  margin-top: 3.5rem;
                  @media (min-width: 768px) {
                    margin-top: 5.5rem;
                  }
                `}
              >
                <input type="hidden" name="form-name" value="contact ratio" />
                <input
                  name="fullName"
                  placeholder="Name"
                  css={formCSS}
                  type="text"
                  className="form-control"
                  required
                />
                <input
                  name="emailAddress"
                  placeholder="Email"
                  css={formCSS}
                  type="email"
                  className="form-control"
                  required
                />
                <textarea
                  name="message"
                  placeholder="Message"
                  css={[
                    formCSS,
                    css`
                      min-height: 180px;
                    `
                  ]}
                  className="form-control"
                  required
                />
                <Recapture
                  css={css`
                    margin-bottom: 1rem;
                  `}
                  sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                />
                <PrimaryButton
                  type="submit"
                  text="Send"
                  css={css`
                    min-width: 200px;
                  `}
                  onClick={e => {
                    // e.preventDefault();
                  }}
                />
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;

export const query = graphql`
  {
    contentfulContactPage {
      pageTitle: title
      metaDescription
      heroBanner {
        title
        subtitle
        buttonText
        buttonLink
        heroImage {
          title
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid
          }
        }
      }
      contactContent {
        md: childMarkdownRemark {
          html
        }
      }
    }
  }
`;
